.login-page {
  input {
    box-sizing: border-box;
  }

  input:focus,
  textarea:focus {
    outline: none;
  }

  input {
    margin-bottom: 1rem;
    margin-top: 0.2rem;
    padding: 10px 10px 10px 10px;
    width: 100%;
    border: 1px solid #d9d9d9;
    //background: #f3f3f3;
  }

  label {
    margin-bottom: 0rem;
    pointer-events: none;
    user-select: none;
    opacity: 1;
    font-size: 0.7rem;
  }

  input:focus ~ label {
    user-select: none;
  }
  input:focus {
    border: 2px solid #005ff1;
  }
}
