@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../styles/grid-breakpoints';

.login-page {
  .login-col {
    background: transparent;
    @include media-breakpoint-up(lg) {
      background: white;
    }
  }
}
